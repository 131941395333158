import React from 'react';
import axios from 'axios';


export const serverPost = async (url, params) => {
    try {
        const options = {
            method: 'POST',
            data: { value: JSON.stringify(params) },
            url,
        };

        var result = await axios(options);
        return result?.data;
    } catch (error) {
        if (error.response.data.statusCode == 401) {
            clearAuth();
            window.location.href = "/security/login";
        } else {
            console.log(error);
            return error.response;
        }           
    }
}


export const serverAPIPost = async (url, params, canRedirect = true) => {
    try {
        const options = {
            method: 'POST',
            data: params ,
            url,
        };

        var result = await axios(options);
        return result?.data;
    } catch (error) {
        if (canRedirect) {
            if (error.response.data.statusCode == 401) {
                // clearAuth();
                // window.location.href = "/security/login";
            } else {
                console.log(error);
                return error.response;
            }
        } else
            return error.response;
    }
}


export const serverFormPost = async (url, params , canRedirect=true) => {
    try {
        const options = {
            method: 'POST',
            data:  params,
            url,
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        };
        var result = await axios(options);
        return result?.data;
    } catch (error) {
        if (canRedirect) {
            if (error.response.data.statusCode == 401) {
                clearAuth();
                window.location.href = "/security/login";
            } else {
                console.log(error);
                return error.response;
            }
        } else { clearAuth(); return error.response; }
    }
}

export const serverPut = async (url, params) => {
    try {
        const options = {
            method: 'PUT',
            data: { value: JSON.stringify(params) },
            url,
        };
        var result = await axios(options);
        return result?.data;
    } catch (error) {
        if (error.response.data.statusCode == 401) {
            clearAuth();
            window.location.href = "/security/login";
        } else {
            console.log(error);
            return error.response;
        }
            
    }
}

export const serverGet = async (url) => {
   
    try {
        var result = await axios.get(url);
        return result.data;
    } catch (error) {
        if (error.response.data.statusCode == 401) {
            
            clearAuth();
            window.location.href = "/security/login";
        }
        else {
            console.log(error)
            return error.response; 
        }                 
    }
}

export const serverDownloadFile = async (url, doc_name) => {

    const headers = {
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.get(url, { responseType: 'arraybuffer', headers: headers });
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const objUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objUrl;
        link.setAttribute('download', doc_name);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        if (error.response?.data?.statusCode == 401) {
            clearAuth();
            window.location.href = "/security/login"
        }
        else {
            console.log(error)
            return error.response;           
        }      
    }    

}

export const serverDelete = async (url, params) => {
    try {
        const options = {
            method: 'DELETE',
            data: { value: JSON.stringify(params) },
            url,
        };
        var result = await axios(options);
        return result.data;
    } catch (error) {
        if (error.response?.data?.statusCode == 401) {
            clearAuth();
            window.location.href = "/security/login"
        }
        else {
            console.log(error);
            return error.response;
        }                   
    }
}

export const Prog = (props) => {
    return (
        <>
            {
                props.loading == true ?
                    <>
                        <div className="modal-backdrop fade show">
                            <div className="modal" style={{ display: "block" }} >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <div>Loading...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ""
            }
        </>
    );
}

export const clearAuth = () => {
   // localStorage.removeItem("isAuth");
    //localStorage.clear();
    const currentURL = `${window.location.pathname}${window.location.search}`;
    sessionStorage.setItem("redirectAuthentication", JSON.stringify(currentURL));
    localStorage.removeItem("memberId");
    sessionStorage.removeItem('redirectUrl');
    sessionStorage.removeItem('isAuth');
    sessionStorage.removeItem('sessionData');
    sessionStorage.setItem("isAuth", false)
}


