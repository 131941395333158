import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { serverGet, serverPost, serverPut } from '../../../../middleware/http';
import { apiConst } from '../../../../services/constant/constant';
import { formValidation, getMsg, getSearchParams } from '../../../../services/utils/validation';
import ActionStatus from '../../../../services/utils/actionStatus';
import ProgressContext from '../../../../services/utils/progress';
import NotifyContext from '../../../../services/utils/notify';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
import DoctorSummary from '../summary/DoctorSummary';
import ScheduleHeader from '../summary/ScheduleHeader';

export default function VitalsForms(props) {

    const [open, setOpen] = useState(true);
    const [vitalListData, setVitalListData] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const { notifyToast } = useContext(NotifyContext);

    const navigate = useNavigate();


    const toggleAll = () => {
        const updatedVitalsData = vitalListData?.healthGroup?.map((item, i) => {
            return { ...item, isShow: !open };
        });

        setOpen(!open);

        setVitalListData({ ...vitalListData, healthGroup: updatedVitalsData });

    };

    const handleShow = (index) => {

        const updatedVitalsData = vitalListData.healthGroup.map((item, i) =>
            i !== index && !open ? { ...item, isShow: false } : { ...item }
        );

        updatedVitalsData[index].isShow = !updatedVitalsData[index].isShow;

        const close_all = updatedVitalsData?.filter((list) => list?.isShow == true) || [];
        if (close_all?.length == 0)
            setOpen(false);

        setVitalListData({ ...vitalListData, healthGroup: updatedVitalsData });
    }


    const getList = async () => {
        try {
            progParams(true);
            const urlSearchParams = new URLSearchParams(window.location.search);
            const encounter_id = urlSearchParams.get('eid');

            let member_id = localStorage.getItem("memberId");

            if (!member_id) {
                const patient_res = await serverGet(apiConst.patientinfo);
                if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                    member_id = patient_res?.data?.memberId;
                localStorage.setItem("memberId", patient_res?.data?.memberId);
            }

            let fmid = getSearchParams("fmid");

            const res = await serverGet(`${apiConst.getvital}${fmid ? fmid : member_id}${encounter_id ? `?eid=${encounter_id}` : ""}`)
            if (res.data != null && res.status == 1) {

                if (res.data?.healthGroup?.length > 0) {
                    for (var health_Group of res.data?.healthGroup) {

                        health_Group.isShow = true;
                    }
                }
                setVitalListData(res?.data);
                progParams(false);
            }
        } catch (error) {
            console.log(error);
            progParams(false);

        }

    }


    const checkVitalValidation = async () => {

        try {
            progParams(true);
            let member_id = localStorage.getItem("memberId");
            let fmid = getSearchParams("fmid");

            const encounter_id = getSearchParams('eid');

            const res = await serverGet(`${apiConst.getvital}${fmid ? fmid : member_id}${encounter_id ? `?eid=${encounter_id}` : ""}`);

            if (res.status == 1 && res?.data != null) {
                const list = res?.data;
                let isRequired = false;
                let id = "";

                const requiredVitals = list?.healthGroup?.filter(vital => vital.isRequired);

                if (requiredVitals?.length > 0) {

                    requiredVitals.forEach(vital => {

                        if (isRequired) {
                            return;
                        }

                        const vitalHealthMetrics = list?.healthMetrics?.filter(metric => metric.healthGroupId == vital.id);

                        vitalHealthMetrics.forEach(healthMetric => {
                            if (isRequired) {
                                return;
                            }

                            id = healthMetric.id;
                            isRequired = true;

                            if (list?.patientHealthMetrics?.length > 0) {
                                const patientHealthMetric = list?.patientHealthMetrics?.filter((patientMetric) => patientMetric.healthMetricId == healthMetric.id && patientMetric.healthGroupId == vital.id && patientMetric?.value != "");
                                if (patientHealthMetric?.length > 0 || healthMetric.code == "BMI") {
                                    isRequired = false;
                                } else {
                                    isRequired = true;
                                }
                            }
                        });
                    });

                    return {
                        required: isRequired,
                        id: id
                    };
                }
            } return false;
        } catch (e) {
            console.error(e);
        } finally {
            progParams(false);
        }
    }




    const navigateVital = async () => {
        const isPharmacy = getSearchParams("isp");
        const form_id = getSearchParams("frmid");
        const form_input_id = getSearchParams("frminputid");
        const show_form = getSearchParams("isf");
        const _reascode = getSearchParams("reascode");
        const _typeid = getSearchParams("ftypeid");
        const _phaid = getSearchParams("phaid"); // pharmacyId

        const fmid = getSearchParams("fmid");
        const _snum = getSearchParams("snum");
        const _brtype = getSearchParams("brtype");
        const _rsid = getSearchParams("rsid") // residingStateId

        //if (form_id) {
        //    updateSearchParams("frmid", form_id);
        //}

        const requiredVital = await checkVitalValidation();

        if (requiredVital?.required && requiredVital?.id) {
            notifyToast(352);
            const vitalIndex = vitalListData?.healthMetrics?.find((item) => item?.id == requiredVital?.id);
            if (vitalIndex) {
                const data = vitalListData?.healthGroup;
                const _indexHg = data.findIndex((item) => item?.id == vitalIndex?.healthGroupId) // index healthGroup

                if (_indexHg != -1) {
                    data[_indexHg].isShow = true;
                    setVitalListData({ ...vitalListData, healthGroup: data });
                }
                const inputElement = document.querySelector(`input[name="${requiredVital?.id}"]`);
                if (inputElement) {
                    inputElement.focus();
                }
            }
        } else {
            setTimeout(() => {
                if (form_id && show_form == "t") {
                    navigate(`/intakeforms?memberid=${getSearchParams("memberid")}&isp=${isPharmacy == "t" && getSearchParams("rsid") ? "t" : "f"}${_phaid ? `&phaid=${_phaid}` : ""}&ad=${getSearchParams("ad")}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}${`&frmid=${form_id}`}${form_input_id ? `&frminputid=${form_input_id}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                } else if (isPharmacy == "t" && _rsid && _rsid != "") {
                    navigate(`/pharmacy?memberid=${getSearchParams("memberid")}&isp=${"t"}&ad=${getSearchParams("ad")}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                } else {
                    navigate(`/appointment/summary?memberid=${getSearchParams("memberid")}&isp=${"f"}&ad=${getSearchParams("ad")}${_phaid ? `&phaid=${_phaid}` : ""}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                }
            }, 1000)
        }
    }


    useEffect(() => {
        document.title = "Vitals";
        getList();
    }, []);

    return (
        <>
            <ScheduleHeader title={getLanguageContent("vtals")} />
            <div className="d-flex flex-md-row flex-column">
                <DoctorSummary />
                <div className="flex-grow-1">
                    <div className="card border-0">
                        <div className="card-body p-2 p-md-4">
                            <div className="d-flex justify-content-end">
                                {vitalListData?.healthGroup?.length > 1 && (
                                    <button className="btn btn-secondary text-end mb-3" onClick={toggleAll}>
                                        {open ? getLanguageContent("clpse") : getLanguageContent("expnd")}
                                    </button>
                                )}


                            </div>
                            {vitalListData?.healthGroup?.length > 0 && vitalListData?.healthGroup?.map((list, i) => (
                                <>
                                    <VitalForm id={list?.id} name={list?.displayName} show={list?.isShow} handleShow={handleShow} index={i} required={list?.isRequired} value={vitalListData?.patientHealthMetrics} getList={getList} />
                                </>
                            ))}
                            {vitalListData?.healthGroup?.length > 0 && (
                                <div className="row">
                                    <div className="mb-5  text-end">
                                        <button onClick={() => navigateVital()} className="btn btn-primary btn-lg mb-2">{getLanguageContent("vitcon")}</button>
                                    </div>
                                </div>
                            )}
                            {!vitalListData?.healthGroup || vitalListData?.healthGroup?.length == 0 ? <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" /> : null}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


const VitalForm = ({ name, id, show, handleShow, index, required, value, getList }) => {

    const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm();

    const [vitalForm, setVitalForm] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const member_id = localStorage.getItem("memberId");
    const { getLanguageContent } = useLanguageRes();
    const { accountDate } = useAccountSettings();


    const onSubmit = async (data, e) => {
        e.preventDefault();

        const urlSearchParams = new URLSearchParams(window.location.search);
        const encounter_id = urlSearchParams.get('eid');

        const formdata = [];
        const vital_Form = [...vitalForm];


        const _data = { ...data };
        delete _data[`takenOn${name}`];

        if (Object.values(_data).some(value => !!value)) {
        } else {
            return notifyToast(338);
        }

        let _isUpdate = false;

        vital_Form?.forEach((item) => {
            const feetKey = `${item.id}_feet`;
            const inchKey = `${item.id}_inch`;

            if (data[feetKey] !== undefined || data[inchKey] !== undefined) {
                const feetValue = data[feetKey] || 0; 
                const inchValue = data[inchKey] || 0; 

                item.value = `${feetValue}'${inchValue}"`;
            } else {
                item.value = data[item.id];
            }
                const form_data = {
                    value: item?.value || "",
                    takenOn: new Date(startDate), // Moment(currentDate).format('YYYY-MM-DDTHH:mm:ss'),
                    healthGroupMetricId: item?.healthGroupMetricId,
                    unitId: item?.unitId
                };

                if (value) {
                    const hmVal = value?.find(h => h.healthMetricId == item.id && h?.healthGroupMetricId == item?.healthGroupMetricId);
                    if (hmVal) {
                        form_data.id = hmVal.id;
                        _isUpdate = true;
                        form_data.sessionId = hmVal.sessionId;
                    }
                }

                if (encounter_id && encounter_id !== "") {
                    form_data.encounterId = encounter_id;
                }
                formdata.push(form_data);           
        });       

        if (_isUpdate) {
            vitalsUpdate(formdata);
        } else {
            vitalsSave(formdata);
        }

        //vitalsSave(formdata);
    };


    const vitalsUpdate = (formdata) => {
        progParams(true);
        let fmid = getSearchParams("fmid");

        serverPut(`${apiConst.vitalUpdate}${fmid ? fmid : member_id}`, formdata).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    getList();
                }
                else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const vitalsSave = (formdata) => {
        progParams(true);
        let fmid = getSearchParams("fmid");

        serverPost(`${apiConst.vitalsave}${fmid ? fmid : member_id}`, formdata).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    getList();
                }
                else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const defaultValuesUpdate = (data) => {
        if (!data || !Array.isArray(data)) {
            return {};
        }
        return data.reduce((acc, curr) => {
            if (curr.unitCode.includes('ft') && curr.value) {
                const regex = /^(\d+)'(\d+)\"$/;
                const match = curr.value.match(regex);

                if (match) {
                    acc[`${curr.id}_feet`] = match[1];
                    acc[`${curr.id}_inch`] = match[2]; 
                } else {
                    acc[`${curr.id}_feet`] = '';
                    acc[`${curr.id}_inch`] = '';
                }
            } else {
                acc[curr.id] = curr.value;
            }

            return acc;
        }, {});
    };

    const getForm = async () => {
        try {
            progParams(true);
            const res = await serverGet(`${apiConst.getvitalform}${id}`);

            if (res?.data != null && res.status == 1) {
                setTimeout(() => {
                    const vitaldata = res?.data.map((item1) => {
                        const matchingObj = value.find(item2 => item2.healthMetricId == item1.id && item2.healthGroupMetricId == item1?.healthGroupMetricId);

                        if (matchingObj) {
                            return { ...item1, value: matchingObj.value };
                        } else {
                            return { ...item1 };
                        }
                    });
                    setVitalForm(vitaldata);
                    setValue(`takenOn${name}`, startDate);
                    progParams(false);

                }, 2500)

            }
        } catch (error) {
            progParams(false);

            console.log(error);
        }
    }
  

    const toggleForm = () => {
        handleShow(index);
    }

    useEffect(() => {
        if (id) {
            getForm();
        }
    }, []);

    useEffect(() => {
        const defaultValues = defaultValuesUpdate(vitalForm);
        reset(defaultValues);
    }, [reset, vitalForm]);

    return (
        <>
            <div className="card mb-3">
                <div className="card-title">
                    <div className="d-flex align-items-center pt-2">
                        <div className="mx-4 truncate-text flex-grow-1">
                            <h6 onClick={toggleForm} className="text-primary pointer-class">
                                {name}
                                <span className="ms-2 text-dark">
                                    {show ?
                                        <svg width="7" height="12"><use xlinkHref="#svg_arrowright"></use> </svg>
                                        :
                                        <svg width="12" height="7"><use xlinkHref="#svg_arrowdown"></use> </svg>
                                    }
                                </span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    {(show) && (
                        <>
                            {(Array.isArray(vitalForm) && vitalForm?.length > 0) && (
                                <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                                    <div className="row">
                                        <div className="mb-3 col-lg-6" >
                                            <label className="form-label" htmlFor={`takenOn`}>{getLanguageContent("rcdon")}<span className="text-danger star">*</span></label>
                                            <Controller
                                                name={`takenOn${name}`}
                                                control={control}
                                                rules={{ required: getMsg(301) }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        //autoFocus={index == 0 ? true : false}
                                                        name={`takenOn${name}`}
                                                        id={"takenOn"}
                                                        className="form-control"
                                                        maxDate={new Date()}
                                                        placeholderText={getLanguageContent("rcdon")}
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            field.onChange(date);
                                                            setStartDate(date);
                                                        }}
                                                        timeInputLabel="Time"
                                                        dateFormat={accountDate?.value ? `${accountDate?.value?.toLowerCase()?.replace(/mm/, "MM")} h:mm aa` : "MM/dd/yyyy h:mm aa"}
                                                        wrapperClassName="w-100"
                                                        showTimeInput
                                                        timeFormat="h:mm"
                                                    />
                                                )}
                                            />
                                            {(errors["takenOn"]?.message && !startDate) && <p className="text-danger">{errors["takenOn"]?.message}</p>}
                                        </div>
                                    </div>
                                    {vitalForm?.map((list, i) => (
                                        <>
                                            {list?.unitCode == "ft" && list?.controlTypeId == 1 ?
                                                <>
                                                    <div className="row align-items-center g-2 mb-1" key={i}>
                                                        <label htmlFor={list?.id} className="form-label">
                                                            {list?.displayName}
                                                            {list?.unitCode && <span>({list?.unitCode})</span>}
                                                            {required && <span className="text-danger">*</span>}
                                                        </label>
                                                        
                                                        <div className="col-11 col-xl-5 col-lg-5">
                                                            <Controller
                                                                name={`${list?.id}_feet`}
                                                                control={control}
                                                                rules={{
                                                                    required: list?.isRequired ? getMsg(301) : false, 
                                                                    pattern: formValidation.feet.pattern,
                                                                }}
                                                                render={({ field }) => (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name={`${list?.id}_feet`}
                                                                        onChange={(e) => {
                                                                            field.onChange(e); 
                                                                        }}
                                                                        defaultValue=""
                                                                        className="form-control"
                                                                        id={list?.id}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="col-1 mb-3 mt-4">{list?.unitCode}</div>

                                                        <div className="col-11 col-xl-5 col-lg-5">
                                                            <Controller
                                                                name={`${list?.id}_inch`}
                                                                control={control}
                                                                rules={{
                                                                    required: false,  
                                                                    pattern: formValidation.inch.pattern,
                                                                }}
                                                                render={({ field }) => (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name={`${list?.id}_inch`}
                                                                        onChange={(e) => {
                                                                            field.onChange(e);
                                                                        }}
                                                                        defaultValue=""
                                                                        className="form-control"
                                                                        id={list?.id}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                       
                                                        <div className="col-1 mb-3 mt-4">in</div>

                                                        <div>
                                                            <span>
                                                                {(errors[`${list?.id}_feet`]?.message || errors[`${list?.id}_inch`]?.message) && (
                                                                    <p className="text-danger">
                                                                        {errors[`${list?.id}_feet`]?.message || errors[`${list?.id}_inch`]?.message}
                                                                    </p>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </> :
                                            list?.controlTypeId == 1 && (
                                                <div className="mb-3" key={list?.id}>
                                                    <label htmlFor={list?.id} className="form-label">
                                                        {list?.displayName}{list?.unitCode ? <span>({list?.unitCode})</span> : ""}{required ? <span className="text-danger">*</span> : ""}
                                                    </label>
                                                    <Controller
                                                        name={list?.id}
                                                        control={control}
                                                        rules={
                                                            {
                                                                required: required ? getMsg(301) : false,
                                                                pattern: list?.dataTypeId == 2 ? formValidation.number.pattern : list?.dataTypeId == 3 ? formValidation.decimalnumbers.pattern : null
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                type="text"
                                                                name={list?.id}
                                                                //autoFocus={i == 0 && index == 0 ? true : false}
                                                                // defaultValue={field?.value}
                                                                className="form-control"
                                                                id={list?.id}
                                                                placeholder={`${getLanguageContent("vitent")} ${list?.displayName?.toLowerCase()}`}
                                                            />
                                                        )}
                                                    />
                                                    {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                                </div>
                                            )}
                                        </>
                                    ))}
                                    {(vitalForm?.length > 0) && (
                                        <div className="row">
                                            <div className="text-end">
                                                <button className="btn btn-primary mb-2">{getLanguageContent("vitsve")}</button>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}





