import React, { useContext, useState } from "react"
import { dateFormat, getAge, getMemberId, getStatusColor } from "../../../../services/utils/validation"
import { useEffect } from "react";
import { serverGet } from "../../../../middleware/http";
import { apiConst } from "../../../../services/constant/constant";
import ProgressContext from "../../../../services/utils/progress";
import { useAccountSettings } from "../../../../services/Context/AccSettingContext";
import ActionStatus from "../../../../services/utils/actionStatus";
import { useLanguageRes } from "../../../../services/Context/LanguageContext";
import { useProfile } from "../../../../services/utils/profileContext";


export default function FamilyMemberList({ toggle }) {
    const [familyMemberList, setFamilyMemberList] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { accountDate } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();
    const { contextFamilyList, setContextFamilyList } = useProfile();


    const getList = async () => {
        try {

            progParams(true);
            setFamilyMemberList("load");

            const _memberId = await getMemberId();

            const res = await serverGet(`${apiConst.familymemberget}${_memberId}`)
            progParams(false);

            if (res.status == 1 && res.data) {
                //setContextFamilyList(res.data);
                setFamilyMemberList(res.data);

            } else {
                setFamilyMemberList(null);

            }

        } catch (e) {
            console.log(e)
        }
    }

    const editFamilyMember = (list) => {
        toggle(list);
    }

   // console.log(contextFamilyList);

    useEffect(() => {
        document.title = "Family members"
        getList();
    }, [])
    return (
        <>
            {(Array.isArray(familyMemberList) && familyMemberList?.length > 0) && (
                <>
                    <div className="row d-none d-md-flex text-secondary">
                        <div className="col-4">
                            <h6>{getLanguageContent("fmname")}</h6>
                        </div>
                        <div className="col-2">
                            <h6>{getLanguageContent("fmrela")}</h6>
                        </div>
                        <div className="col-3">
                            <h6>{getLanguageContent("fmsats")}</h6>
                        </div>
                        <div className="col-3">
                            <h6>{getLanguageContent("fmpoac")}</h6>
                        </div>
                    </div><hr />
                </>
            )}
            {(Array.isArray(familyMemberList) && familyMemberList?.length > 0) && familyMemberList?.map((list, index) => (
                <>
                    <div className="row justify-content-start justify-content-md-center" key={index}>
                        <div className="col-8 col-md-6 m-auto">
                            <div className="row">
                                <div className="col-md-8 flex-column m-auto">
                                    <div className="text-primary pointer-class mb-2" onClick={() => editFamilyMember(list)}>
                                        <u>{list?.firstName}  {list?.lastName}</u>
                                    </div>
                                    <div>
                                        {dateFormat(list?.dob, accountDate?.value)} | {list?.gender}
                                    </div>
                                </div>
                                <div className="d-none d-md-flex col-md-4 mb-3 m-md-auto">
                                    <div>
                                        {list?.relationship}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-md-6 m-auto">
                            <div className="row">
                                <div className="col-md-6  m-md-auto">
                                    <div>
                                        <span className={`badge rounded-pill text-bg-${getStatusColor(list?.statusId)}`}>{list?.statusName}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 m-md-auto">
                                    <div>
                                        {list?.platformAccessId == "2" ? <u className="text-primary pointer-class" onClick={() => editFamilyMember(list)}>{list?.platformAccess}</u> : list?.platformAccess}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="m-2" />
                </>

            ))}
            {(Array.isArray(familyMemberList) && familyMemberList?.length > 0) && (
                <span>{getLanguageContent("fmaduf")}</span>
            )}
            {((!familyMemberList || familyMemberList?.length == 0 || familyMemberList == null) && (familyMemberList != "load")) && <ActionStatus description={getLanguageContent("fmnrfo")} imageid="#svg_failed" type="search" />}
        </>
    )
}



//     <div className="col-4">
//        <h6>{getLanguageContent("fmbnam")}</h6>
//</div>
//<div className="col-3">
//   <h6>{getLanguageContent("fmbdte")}</h6>
//</div>
//<div className="col-2 text-nowrap">
//    <h6>{getLanguageContent("fmbgnd")}</h6>
//</div>
//<div className="col-3 text-nowrap">
//    <h6>{getLanguageContent("fmbrel")}</h6>
//</div>