import React, { useContext, useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import he from "he";
import * as bootstrap from 'bootstrap';
import DoctorSummary from '../consultation/summary/DoctorSummary'
import { ecnSession, getMsg, formValidation, getSearchParams, updateSearchParams, isEnabled, isFeautureEnabled, getUtcTimeNow, isArray } from '../../../services/utils/validation';
import DocumentUpload from '../../fileUpload/DocUpload';
import { serverDelete, serverGet, serverPost, serverPut } from '../../../middleware/http';
import { apiConst, appConst } from '../../../services/constant/constant';
import ProgressContext from '../../../services/utils/progress';
import NotifyContext from '../../../services/utils/notify';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useDoctor } from '../../../services/Context/DoctorContext';
import LazyImageComponent from '../../../services/utils/LazyImageComponent';

export default function Reason() {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();
    const [reasonDoc, setReasonDoc] = useState([]);
    const [reasonsList, setReasonsList] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedReason, setSelectedReason] = useState([]);
    const [showPharmacy, setshowPharmacy] = useState(false);
    const [conditionDocs, setConditionDocs] = useState([]);
    const [showCondition, setShowCondition] = useState(false);
    const [serviceCondition, setServiceCondition] = useState(null);
    const [selectedServiceCondition, setSelectedServiceCondition] = useState(null);
    const [symptomList, setSymptomList] = useState(null);
    const [defaultSymptomList, setDefaultSymptomList] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { accountSettings, conditionValue, conditionDocLength, accountModules } = useAccountSettings();
    const { doctorContext } = useDoctor();

    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();
    const modalBackdropRef = useRef(null);
    const modalConfirmRef = useRef(null);


    const onSubmit = async (data) => {
        try {

            // console.log(data);

            progParams(true);
            const sizeInBytes = 10 * 1024 * 1024
            let size_validation = false;
            let type_validation = false;
            let name_validation = false;
            const member_id = localStorage.getItem("memberId");

            if (reasonDoc.length > 0) {
                reasonDoc.forEach((item) => {
                    const doc = item?.src
                    if (doc?.size > sizeInBytes && !formValidation.filename_val.test(doc.name) && !appConst.fileTypes.reason.validationTypes?.includes(doc?.type)) {
                        size_validation = true;
                        type_validation = true;
                        name_validation = true;
                    } else if (!appConst.fileTypes.encounter.validationTypes?.includes(doc?.type)) {
                        type_validation = true;
                    } else if (doc?.size > sizeInBytes) {
                        size_validation = true;
                    } else if (!formValidation.filename_val.test(doc?.name)) {
                        name_validation = true;
                    }
                })
            }

            const sessionStore = ecnSession();
            const _brtype = getSearchParams("brtype"); // Schedule method book now or shared calender
            const app_date = getSearchParams("ad");
            const duration_name = getSearchParams("dn");
            const enc_mode = getSearchParams("m");
            const encounterModeId = getSearchParams("emid");
            const durationId = getSearchParams("did");
            let minimum_price = getSearchParams("minP") || "";
            const specialty_id = getSearchParams("spid");
            const parent_id = getSearchParams("parent");
            const f_typeid = getSearchParams("ftypeid");
            const fmid = getSearchParams("fmid");
            const _snum = getSearchParams("snum"); // Schedule phone number
            let time_slot = getSearchParams("ts");
            const schedule_id = getSearchParams("scheId");
            const encounter_id = getSearchParams("eid");
            const _rsid = getSearchParams("rsid");

            const enc_reason = selectedReason[0]?.id;
            const formadata = {};
            const document_files = [];

            formadata.memberId = member_id;
            formadata.specialityId = specialty_id;
            formadata.typeId = 1;
            formadata.reasonId = enc_reason;
            formadata.providerId = getSearchParams("memberid");
            formadata.encounterModeId = getSearchParams("emid");
            formadata.durationId = getSearchParams("did");
            formadata.scheduleOn = time_slot;
            formadata.note = data?.reasonNote;
            formadata.statusId = appConst.encStatusId.initiated; // requested AAA isEnabled(accountSettings, "AAA", 0) ? appConst.encStatusId.requested :
            formadata.encounterStatusId = appConst.encStatusId.encounterInitated;

            if (_rsid && _rsid != "") {
                formadata.residingStateId = _rsid;
            }

            if (parent_id) {
                formadata.parentId = parent_id;
            } if (f_typeid) {
                formadata.consultationTypeId = f_typeid;
            }

            if (parent_id && f_typeid && !minimum_price) {
                minimum_price = doctorContext?.minimumPrice?.price ? doctorContext?.minimumPrice?.price : ""
            }

            if (fmid) {
                formadata.memberId = fmid;
            }

            if (_brtype) {
                formadata.methodId = _brtype == "RW8" ? 2 : _brtype == "GT6" ? 3 : _brtype == "SD3" ? 1 : "1"
            }

            if (formadata.methodId == 3) {
                const res = await serverGet(`${apiConst.getNotAssignedMember}`);
                if (res?.status == 1 && res?.data) {
                    formadata.providerId = res?.data?.id;
                    updateSearchParams("memberid", res?.data?.id);
                }

                const _currentUtcTime = getUtcTimeNow();
                time_slot = _currentUtcTime;
                formadata.scheduleOn = _currentUtcTime;
                updateSearchParams("ts", _currentUtcTime);
            }

            if (_snum) {
                formadata.phoneNo = _snum;
            }
            if (serviceCondition?.length > 0 && data?.servicetype) {
                formadata.serviceOptionId = data?.servicetype;
            }

            if (encounter_id && encounter_id?.trim() !== "") {
                formadata.encounterId = encounter_id;
            }

            if (Array.isArray(data?.symptom) && data?.symptom?.length > 0) {
                formadata.symptoms = JSON.stringify(data.symptom.map(list => ({ SymptomId: list?.symptomId })));
            } else {
                if (formadata.encounterId) {
                    const _defaultSymptom = [
                        {
                            SymptomId: null
                        }
                    ]
                    formadata.symptoms = JSON.stringify(_defaultSymptom)
                }
            }

            //console.log(formadata);

            //return;

            if (sessionStore && sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
                for (const doc of sessionStore?.uploadid) {
                    if (doc?.type == window.location.pathname) {
                        const temp = {
                            memberId: fmid ? fmid : member_id,
                            recordTypeId: appConst.recordType.encounter,
                            fileName: doc?.name,
                            filePath: doc?.src,
                        }
                        if (doc?.preview)
                            temp.typeId = 301;
                        document_files.push(temp);
                    }
                }
            }




            if (size_validation && type_validation) {
                notifyToast(333);
            } else if (type_validation) {
                notifyToast(330);
            } else if (size_validation) {
                notifyToast(331);
            } else if (name_validation) {
                notifyToast(337);
            } else {
                const path = `${apiConst.schedulecreate}/${schedule_id?.trim() ? schedule_id : "-"}`;
                let res = await serverPost(path, { schedule: formadata, docs: document_files })

                if (res?.status == 1 && formadata.methodId == 3) {
                    const formData = {
                        durationId: formadata?.durationId,
                        specalityId: formadata?.specialityId,
                        patientMemberId: formadata?.memberId,
                        methodId: formadata.methodId
                    }
                    if (formadata?.residingStateId) {
                        formData.residingStateId = formadata?.residingStateId
                    }
                    // const memberScheduleSave = await requestProvider(res?.data?.encounterId, formData);
                    const memberScheduleSave = await serverPost(`${apiConst.providerRequest}${res?.data?.encounterId}`, formData);

                    if (memberScheduleSave?.status != 1) {
                        res = memberScheduleSave?.res;
                    }
                }

                if (res?.status == 1) {
                    sessionStorage.removeItem("formsStorage");
                    notifyToast(res?.messageCode[0]);
                    const reason_documents = sessionStore?.uploadid?.filter(item => item.type !== window.location.pathname);
                    const provider_member_id = getSearchParams("memberid");
                    const _reascode = getSearchParams("reascode");
                    let vitalRes = isEnabled(accountSettings, "PHF", 1);
                    let encounterFormsRes = isEnabled(accountSettings, "PIF", 1);
                    let _productId = [];  // assign Product-Specific Pharmacy
                    let _pharmacyId = "";

                    ecnSession(reason_documents, "uploadid");
                    updateSearchParams("scheId", res?.data?.id); // encounter id || schedule id used for pharmacy & payment
                    updateSearchParams("eid", res?.data?.encounterId); // encounter id used for visit detail & documents
                    updateSearchParams("minP", minimum_price); // encounter id used for visit detail & documents
                    if (formadata?.serviceOptionId) updateSearchParams("speicalityservice", formadata.serviceOptionId); // service option id                                         
                    if (data?.reasonNote?.trim()) updateSearchParams("rnote", data?.reasonNote); //Encounter note

                    if (vitalRes)
                        vitalRes = await serverGet(`${apiConst.getvital}${fmid ? fmid : member_id}${res?.data?.encounterId ? `?eid=${res?.data?.encounterId}` : ""}`); // check available vital list 
                    if (encounterFormsRes)
                        encounterFormsRes = await serverGet(`${apiConst.formsList}${fmid ? fmid : member_id}?sid=${specialty_id}&areaid=${appConst.areaId.intakeForms}&eid=${res?.data?.encounterId}`); // check available forms  list
                    const isVitals = vitalRes?.status == 1 && vitalRes?.data?.healthGroup?.length > 0 && vitalRes?.data?.healthMetrics?.length > 0;
                    const is_forms = Array.isArray(encounterFormsRes?.data) && encounterFormsRes?.data?.length > 0;
                    //Pharmacy settings

                    if (isEnabled(accountSettings, "PSR", 1)) {
                        let orderJson = JSON.parse(localStorage.getItem("orderDetail"));
                        if (isArray(orderJson?.orderDetails)) {
                            orderJson?.orderDetails?.map((list) => {
                                _productId.push({
                                    code: list?.Code
                                })
                            })
                        }
                    }

                    const _isShowPharmacy = isFeautureEnabled(accountModules, appConst.preferedPharmacy.pharmacyEmail) || isFeautureEnabled(accountModules, appConst.preferedPharmacy.pharmacyElectronic);
                    const _isPharmacyPage =  _productId.length == 0 && Array.isArray(showPharmacy) && showPharmacy.some((list) => list?.code == appConst.preferedPharmacy.patient) && _isShowPharmacy && _rsid && _rsid != ""  // to check need to show pharmacy page or not
                    const _assignSystemPharmacy = Array.isArray(showPharmacy) && showPharmacy.some((list) => list?.code == appConst.preferedPharmacy.system) && _isShowPharmacy  // assign system pharmacy
                   
                    if (_assignSystemPharmacy || _productId.length > 0) {
                        const pharmacyList = await serverGet(`${apiConst.preferencelist}${fmid ? fmid : member_id}?providerid=${provider_member_id}${_rsid ? `&rid=${_rsid}` : ""}${_productId.length > 0 ? `&ndccode=${JSON.stringify(_productId)}` : ""}`);
                        if (pharmacyList?.status == 1 && pharmacyList?.data && Array.isArray(pharmacyList?.data) && pharmacyList?.data?.length == 1) {
                            const pharmacyFormData = {
                                memberId: fmid ? fmid : member_id,
                                preferredPharmacyId: pharmacyList?.data[0]?.id,
                            }
                            const updateSystemPharmacy = await serverPut(`${apiConst.encounterpharmacy}${res?.data?.encounterId}`, pharmacyFormData);
                            if (updateSystemPharmacy?.status == 1) {
                                _pharmacyId = pharmacyList?.data[0]?.id
                            }
                        }
                    }

                    //check form account settings 
                    if (isVitals) {
                        navigate(`/vital/add?memberid=${provider_member_id}&isp=${_isPharmacyPage ? "t" : "0"}${_pharmacyId ? `&phaid=${_pharmacyId}` : ""}&isf=${is_forms ? "t" : "0"}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${selectedReason[0]?.name}&eid=${res?.data?.encounterId}&schid=${res?.data?.id}&emid=${encounterModeId}&did=${durationId}&minP=${minimum_price}&spid=${specialty_id}${_rsid ? `&rsid=${_rsid}` : ""}${is_forms ? `&frmid=${encounterFormsRes?.data[0]?.formId}` : ""}${is_forms && encounterFormsRes?.data[0]?.formInputId ? `&frminputid=${encounterFormsRes?.data[0]?.formInputId}` : ""}&reascode=${_reascode}${f_typeid ? `&ftypeid=${f_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                    } else if (is_forms) {
                        navigate(`/intakeforms?memberid=${provider_member_id}&isp=${_isPharmacyPage ? "t" : "0"}${_pharmacyId ? `&phaid=${_pharmacyId}` : ""}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${selectedReason[0]?.name}&eid=${res?.data?.encounterId}&schid=${res?.data?.id}&emid=${encounterModeId}&did=${durationId}&minP=${minimum_price}&spid=${specialty_id}${_rsid ? `&rsid=${_rsid}` : ""}${is_forms ? `&frmid=${encounterFormsRes?.data[0]?.formId}` : ""}${is_forms && encounterFormsRes?.data[0]?.formInputId ? `&frminputid=${encounterFormsRes?.data[0]?.formInputId}` : ""}&reascode=${_reascode}${f_typeid ? `&ftypeid=${f_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                    } else if (_isPharmacyPage && _rsid && _rsid != "") {
                        navigate(`/pharmacy?memberid=${provider_member_id}&isp=${"1"}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${selectedReason[0]?.name}&eid=${res?.data?.encounterId}&schid=${res?.data?.id}&emid=${encounterModeId}&did=${durationId}&minP=${minimum_price}&spid=${specialty_id}${_rsid ? `&rsid=${_rsid}` : ""}&reascode=${_reascode}${f_typeid ? `&ftypeid=${f_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                    } else {
                        navigate(`/appointment/summary?memberid=${provider_member_id}&isp=${"0"}${_pharmacyId ? `&phaid=${_pharmacyId}` : ""}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${selectedReason[0]?.name}&eid=${res?.data?.encounterId}&schid=${res?.data?.id}&emid=${encounterModeId}&did=${durationId}&minP=${minimum_price}&spid=${specialty_id}${_rsid ? `&rsid=${_rsid}` : ""}&reascode=${_reascode}${f_typeid ? `&ftypeid=${f_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${encodeURIComponent(_snum)}` : ""}&brtype=${_brtype}`);
                    }
                }
                else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            progParams(false);
        }
    };


    const getAccountSettings = async () => {
        const settingsCode = "PPS" // PPS,PTY  
        try {
            const getsetting = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(settingsCode)}`); // setting values
            const getvalue = await serverGet(`${apiConst.getsettingoptions}/${settingsCode}`); // setting options display text
           
            const isvalid_array = Array.isArray(getsetting?.data) && Array.isArray(getvalue?.data); // check is both are valid arrays

            if (isvalid_array && getsetting.data.length > 0 && getvalue.data.length > 0) {
                const pharmacy_settings = getsetting?.data?.filter(list => list?.settingCode === "PPS"); // to get prefered pahramac selection sysytem or patient 

                if (pharmacy_settings.length > 0) {
                    const { value: settingValue, settingOptionId: settingId } = pharmacy_settings[0];

                    if (settingValue) {
                        const settingValues = settingValue.split(",");
                        const result = getvalue?.data?.filter(item => settingValues?.includes(item?.settingOptionId) || item?.settingOptionId == settingId); // if prescription equal to setting id or setting option id


                        if (result?.length > 0) {
                            setshowPharmacy(result);  // if prescription includes erx  && residing state is enabled  returns true to show pharmcay page else payment page 
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    };



    const reasonList = async () => {
        const specialty_id = getSearchParams("spid");
        progParams(true);
        serverGet(`${apiConst.getSpecialityreason}${specialty_id}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1 && res?.data) {
                    setReasonsList(res?.data);
                    const reason_id = getSearchParams("rid");
                    if (reason_id && reason_id != "") {
                        const reason = res?.data?.filter((list) => list?.id == reason_id);
                        if (reason?.length > 0) {
                            reasonOnChange(reason[0]?.id, res?.data)
                        }
                        setSelectedReason(reason);
                    }

                    if (Array.isArray(res?.data) && res?.data?.length == 1 && res?.data[0]?.code?.trim() == "OTH") {
                        setSelectedReason(res?.data);
                        updateSearchParams("reascode", res?.data[0]?.code?.trim());
                    } else {
                        if (Array.isArray(res?.data) && res?.data?.length == 1 && !reason_id) {
                            setSelectedReason(res?.data);
                            reasonOnChange(res?.data[0]?.id, res?.data);
                        }
                        setShowCondition(true);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        })
    };

    const serviceOptionList = () => {
        const specialty_id = getSearchParams("spid");
        progParams(true);
        serverGet(`${apiConst.getSpecialityServiceOption}${specialty_id}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1 && res?.data) {
                    setServiceCondition(res?.data);
                    const _serviceCondition = getSearchParams("speicalityservice");
                    if (_serviceCondition) {
                        const _result = res?.data?.find((list) => list?.id?.toLowerCase() == _serviceCondition?.toLowerCase())
                        if (_result) {
                            setSelectedServiceCondition(_result);
                        }
                    } else if (Array.isArray(res?.data) && res?.data?.length == 1) {
                        setSelectedServiceCondition(res?.data[0]);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        })
    };


    function openModal(id) {
        const modal = new bootstrap.Modal(document.getElementById(id));
        modal.show();
    };



    const reasonOnChange = (value, arr) => {
        let reason = reasonsList?.filter((list) => list?.id == value);
        if (Array.isArray(arr) && arr?.length > 0) {
            reason = arr?.filter((list) => list?.id == value);
        }

        //console.log(value, arr);
        //console.log(reason);
        if (reason?.length > 0) {
            updateSearchParams("rid", reason[0]?.id);
            updateSearchParams("r", reason[0]?.name);
            setSelectedReason(reason);
            getSymptomConditionsList(reason[0]?.id);
            if (reason[0]?.isShowWarning && reason[0]?.warningMessage) {
                //console.log("times")
                openModal("staticBackdrop");
            }
        }
    }





    //console.log(selectedReason)


    const getDocuments = (id) => {
        const member_id = localStorage.getItem("memberId");
        const fmid = getSearchParams("fmid")
        progParams(true);
        serverGet(`${apiConst.doclist}${fmid ? fmid : member_id}?recordid=${id}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    const condition_docs = [];
                    const _docs = [];

                    res?.data?.map((list) => {
                        if (list?.typeId == 301) {
                            condition_docs.push(list)
                        } else {
                            _docs.push(list);
                        }
                    })


                    setConditionDocs(condition_docs);
                    //const _docs = res?.data?.filter((list) => list?.preview);
                    setDocuments(_docs);

                }
            } catch (e) {
                console.log(e)
            }
        })
    }



    const deleteDoc = (id, filepath) => {
        const member_id = localStorage.getItem("memberId");
        const fmid = getSearchParams("fmid")
        //const sessionStore = ecnSession();
        if (window.confirm('Are you sure you want to delete?')) {
            progParams(true);
            serverDelete(`${apiConst.docdelete}/${fmid ? fmid : member_id}?fp=${filepath}&docid=${id}`, "").then((res) => {
                progParams(false);
                try {
                    if (res.status == 1) {
                        const encounter_id = getSearchParams("eid");

                        notifyToast(res.messageCode[0]);
                        getDocuments(encounter_id);
                    }

                } catch (error) {
                    console.log(error);
                }
            }).catch((e) => {
                console.log(e)
            })

        }
    };


    const getHtmlContent = (data) => {
        const cleanHtml = DOMPurify.sanitize(data);
        const decodedHTML = he.decode(cleanHtml);

        return decodedHTML;
    }


    const handleEmergency = () => {
        navigate(`/dashboard`)
    }


    const getSymptomConditionsList = (id) => {
        progParams(true);

        const encounterId = getSearchParams("eid")
        serverGet(`${apiConst.symptomConditions}${id}${encounterId ? `?eid=${encounterId}` : ""}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    //console.log(res);
                    setSymptomList(res?.data);

                    if (encounterId) {

                        const _res = res?.data?.filter((list) => list?.encounterId == encounterId);
                        setDefaultSymptomList(_res)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        })
    }


    //UseEffect to get account settings and inital reason list and speciality service list 

    useEffect(() => {
        const encounter_id = getSearchParams("eid");
        const resaon_note = getSearchParams("rnote");

        if (resaon_note && resaon_note?.trim() !== "")
            setValue("reasonNote", resaon_note);

        document.title = "Reason";
        if (encounter_id && encounter_id !== "") {
            getDocuments(encounter_id);
        }
        const fetchData = async () => {
            try {
                await serviceOptionList();
                await getAccountSettings();
                await reasonList();
                //await getSymptomList();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {

        const sessionStore = ecnSession();
        if (Array.isArray(sessionStore?.uploadid) && sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
            const condition_docs = sessionStore?.uploadid?.filter((list) => list?.preview);
            if (condition_docs?.length > 0)
                setConditionDocs([...conditionDocs, condition_docs]);
        }
        // conditions set astate filter preview 
    }, [])


    useEffect(() => {
        if (selectedReason.length > 0) {
            setValue("reason", selectedReason[0]?.id);
            updateSearchParams("reascode", selectedReason[0]?.code?.trim());
            //setTreatmentPlan(selectedReason[0].treatmentPlan)  OTH
        }
    }, [selectedReason])

    useEffect(() => {
        if (selectedServiceCondition && selectedServiceCondition?.id) {
            setValue("servicetype", selectedServiceCondition?.id);
        }
    }, [selectedServiceCondition]);


    useEffect(() => {
        const bsModalBackdrop = modalBackdropRef.current;
        const bsConfirmModalBackdrop = modalConfirmRef.current;
        return () => {
            const bsModal = bootstrap.Modal.getInstance(bsModalBackdrop);
            if (bsModal) {
                bsModal.hide();
            }

            const bsModalConfirm = bootstrap.Modal.getInstance(bsConfirmModalBackdrop);
            if (bsModalConfirm) {
                bsModal.hide();
            }
        };
    }, []);

    //console.log(conditionDocs);
    return (
        <div className="px-2 px-md-0">
            <ScheduleHeader title={getLanguageContent("consltinfo")} />
            <div className="d-flex flex-md-row flex-column">
                <DoctorSummary pageTitle={"Reason"} />
                <div className="flex-grow-1">
                    <div className="card border-0">
                        <div className="card-body p-2 p-md-4">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                {(Array.isArray(serviceCondition) && serviceCondition?.length > 0) && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="servicetype" className="form-label">{getLanguageContent("svctyp")}<span className="text-danger star">*</span></label>
                                            <select autofocus id="servicetype" className="form-control form-select"  {...register("servicetype", {
                                                required: getMsg(301),
                                            })}>
                                                <option value="">{getLanguageContent("select")}</option>
                                                {serviceCondition.length > 0 && serviceCondition?.map((list, index) => (
                                                    <option value={list?.id} key={index}>
                                                        {list?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.servicetype?.message && <span className="text-danger">{errors.servicetype?.message}</span>}
                                        </div>

                                    </>
                                )}


                                {showCondition && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="reason" className="form-label">{getLanguageContent("resoncnsl")}<span className="text-danger star">*</span></label>
                                            <select autofocus={serviceCondition?.length == 0 ? true : false} id="reason" className="form-control form-select"  {...register("reason", {
                                                required: getMsg(301),
                                                onChange: (e) => reasonOnChange(e.target.value)
                                            })}>
                                                <option value="">{getLanguageContent("select")}</option>
                                                {reasonsList.length > 0 && reasonsList?.map((list, index) => (
                                                    <option value={list?.id} key={index}>
                                                        {list?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.reason?.message && <span className="text-danger">{errors.reason?.message}</span>}
                                        </div>


                                        {(Array.isArray(symptomList) && symptomList?.length > 0) && (
                                            <div className="mb-3">
                                                <label htmlFor="reason" className="form-label">{getLanguageContent("selsym")}</label>
                                                <Controller
                                                    className="my-2"
                                                    name="symptom"
                                                    control={control}
                                                    defaultValue={defaultSymptomList?.length > 0 ? defaultSymptomList : []}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={symptomList}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            getOptionLabel={(option) => option.symptomName}
                                                            getOptionValue={(option) => option.symptomId}
                                                            placeholder={getLanguageContent("siselc")}
                                                            styles={{
                                                                input: (base) => ({
                                                                    ...base,
                                                                    margin: "10px 0",
                                                                }),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}

                                        {(Array.isArray(selectedReason) && selectedReason?.length > 0 && selectedReason[0]?.treatmentPlan) && (
                                            <div className="alert alert-light" role="alert" dangerouslySetInnerHTML={{ __html: selectedReason[0]?.treatmentPlan }}>

                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="reasonNote" className="form-label">{getLanguageContent("nte")}<span className="text-danger">{!showCondition ? "*" : ""}</span></label>
                                    <textarea placeholder={getLanguageContent("nte")} className="form-control" id="reasonNote" rows="3" {...register("reasonNote", { required: !showCondition ? getMsg(301) : false, maxLength: { value: 1000, message: getMsg("305") } })}></textarea>
                                    {errors.reasonNote?.message && <p className="text-danger">{errors.reasonNote?.message}</p>}
                                </div>
                                <div className="mb-3">
                                    {getLanguageContent("dscribehlthissues")}
                                </div>
                                <label className="form-label">{getLanguageContent("docmnt")}</label>
                                <div className="row">

                                </div>
                                <div className="row mb-3">
                                    {documents?.length > 0 && documents?.map((list, index) => (
                                        <div className="col-xl-6 mb-3" key={index}>
                                            <div className="d-flex align-items-center border border-subtle rounded">
                                                <div className="px-2">
                                                    <span className="fs-1 text-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                    </svg></span>
                                                </div>
                                                <div className="py-1 truncate-text  flex-grow-1">
                                                    {list?.fileName}
                                                </div>
                                                <div className="py-2 mx-2">
                                                    <button onClick={() => {
                                                        deleteDoc(list?.id, list?.filePath)
                                                    }} type="button" className="btn btn-outline-dark border-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <DocumentUpload size={10}
                                        setDocState={setReasonDoc}
                                        encounter={false}
                                        fileTypes={appConst.fileTypes.reason.validationTypes}
                                        disText={appConst.fileTypes.reason.displayText}
                                        name="reason-doc"
                                    />
                                </div>


                                {isEnabled(accountSettings, "ACG", 1) && (
                                    <div>
                                        <label className="form-label">Capture or upload pictures with condition</label>

                                        <div className="row g-2 g-md-3 mb-3">
                                            {conditionDocs?.length > 0 && conditionDocs.filter((list) => list?.typeId == 301)?.map((list, index) => (
                                                <div className="col-6 col-sm-4 col-md-3 col-lg-2 " key={index}>
                                                    <div className="border rounded p-1 h-100">
                                                        <div className="position-relative d-flex justify-content-center align-items-center h-100 ">

                                                            <LazyImageComponent

                                                                src={`doc/getimage/true?url=${encodeURIComponent(list?.filePath)}`}
                                                                alt={list?.fileName}
                                                                height="80"
                                                            />
                                                            <button onClick={() => {
                                                                deleteDoc(list?.id, list?.filePath)
                                                            }} type="button" className="btn btn-sm btn-secondary border rounded-circle position-absolute top-0 end-0 border-secondary" style={{ padding: "5px", paddingTop: "3px" }}
                                                            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" /></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <DocumentUpload
                                                size={10}
                                                setDocState={setReasonDoc}
                                                cameraUpload={true}
                                                showPreview={true}
                                                encounter={false}
                                                fileTypes={appConst.fileTypes.reasonCapture.validationTypes}
                                                disText={appConst.fileTypes.reasonCapture.displayText}
                                                name="reason-cond"
                                                setConditionDocs={setConditionDocs}
                                                conditionDocs={conditionDocs}
                                                maxFile={conditionDocLength}
                                                enableCamera={true}

                                            />
                                        </div>
                                        {conditionValue && (

                                            <div dangerouslySetInnerHTML={{ __html: getHtmlContent(conditionValue) }}>

                                            </div>

                                        )}
                                    </div>
                                )}
                                <hr className="my-4" />
                                <div className="text-end">
                                    <button type="submit" className="btn btn-lg btn-primary">{getLanguageContent("prced")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={modalBackdropRef} className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <span className="modal-title" id="staticBackdropLabel">Warning messsage</span>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{ __html: selectedReason[0]?.warningMessage }}>
                            {/*Please call emergency or go to emergency room" and abandon the appointment and take user to dashboard*/}
                        </div>
                        <div className="modal-footer d-flex justify-content-end">
                            {/*<button data-bs-dismiss="modal" type="button" onClick={() => openModal("modalConfirm")} className="btn btn-outline-danger">Yes, I have an emergency</button>*/}
                            <button data-bs-dismiss="modal" type="button" className="btn btn-primary">{getLanguageContent("undpro")}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={modalConfirmRef} className="modal fade" id="modalConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabelConfirm" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/*<div className="modal-header">*/}
                        {/*    <span className="modal-title" id="staticBackdropLabelConfirm">Warning messsage</span>*/}
                        {/*</div>*/}
                        <div className="modal-body text-center text-danger">
                            Please call emergency or go to emergency room
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button data-bs-dismiss="modal" type="button" onClick={() => handleEmergency()} className="btn btn-primary">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


