import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { serverGet, serverPost } from '../../../../middleware/http';
import { apiConst, appConst } from '../../../../services/constant/constant';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import ActionStatus from '../../../../services/utils/actionStatus';
import ProgressContext from '../../../../services/utils/progress';
import { dateTimeFormat, ecnSession, getSearchParams, timeFormat } from '../../../../services/utils/validation';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
export default function ScheduleConfirmation() {

    const [confirmation, setConfirmation] = useState(null);
    const { progParams } = useContext(ProgressContext);
    const { accountDate, cateogrySettings, homeUrl } = useAccountSettings();
    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();

    const getConfirmation = (id) => {
        progParams(true);
        serverGet(`${apiConst.scheduleconfirmation}${id}`).then((res) => {
            progParams(false);
            try {
                if (res?.data && res?.status == 1) {
                    setConfirmation(res?.data);               
                    ecnSession("clear");
                }
            } catch (e) {
                console.log(e)
            }
        })
    }
    
    useEffect(() => {
        const enc_id = getSearchParams("eid");

        document.title = "Appointment confirmation";
        if (enc_id && enc_id?.trim())
            getConfirmation(enc_id);
    }, [])


    useEffect(() => {
        const handleBackButton = () => {
            window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
            window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
            window.history.forward();
        };

        handleBackButton();

        window.addEventListener('popstate', (event) => {
            if (event.state && event.state.name === "browserBack") {
                window.history.forward();
            }
        });

        return () => {
            window.onunload = null;
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);


    return (
        <>
            {confirmation && (
                <div>
                    <div className="card border-0 mb-4">
                        <div className="d-block d-md-flex justify-content-center align-items-center p-3">
                            <div className="me-md-3">
                                <ActionStatus description={""} button={""} fill="#57C032" width="80" height="80" imageid="#icons_success" onsyncchange={""} type="search" showSvg={true} />
                            </div>
                            <div className="text-center">
                                <div className="fs-2">{confirmation?.statusId == appConst.encStatusId.requested ? getLanguageContent("rqssfy") : confirmation?.statusId == appConst.encStatusId.scheduled ? getLanguageContent("schsuccess") : ""}</div>
                                {confirmation?.statusId == appConst.encStatusId.requested && (
                                    <div className="my-2 m-md-0">
                                        {getLanguageContent("subreq")}
                                    </div>
                                )}

                                {confirmation?.encounterNo && <span className="fs-4 d-block">{getLanguageContent("apptrefno")} <strong>{confirmation?.encounterNo}</strong></span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card border-0">
                                <div className="card-body p-4">
                                    <span className="fs-2 text-center mb-4 d-block">{getLanguageContent("apptdetails")} </span>
                                    <div className="d-flex justify-content-center">
                                        <div className="">
                                            <p>{getLanguageContent("patntname")}</p>
                                            <p>{getLanguageContent("prvdrnme")}</p>
                                            <p>{getLanguageContent("dtetime")}</p>
                                            <p>{getLanguageContent("consltmode")}</p>
                                            {(confirmation?.durationId && cateogrySettings && confirmation?.methodId != "3") && <p>{getLanguageContent("durtion")}</p>}
                                        </div>
                                        <div className="ms-4 fw-bold">
                                            {confirmation?.patientName && <p>{confirmation?.patientName}</p>}
                                            {confirmation?.providerName && <p>{confirmation?.providerName}</p>}
                                            {confirmation?.scheduleOn && <p>{dateTimeFormat(confirmation?.scheduleOn, accountDate?.value)} {timeFormat(confirmation?.scheduleOn)}</p>}
                                            {(confirmation?.modeId && cateogrySettings) && <p>{cateogrySettings?.find((list) => list?.id == confirmation?.modeId && list?.group == "encounter_mode")?.name}</p>}
                                            {(confirmation?.durationId && cateogrySettings && confirmation?.methodId != "3") && <p>{cateogrySettings?.find((list) => list?.id == confirmation?.durationId && list?.group == "schedule_duration")?.name}</p>}
                                        </div>
                                    </div>
                                    <div className="text-center my-3">
                                        <Link to="/dashboard">
                                            <button type="button" className="btn btn-primary me-2">{getLanguageContent("dshbrd")}</button>
                                        </Link>
                                        {homeUrl?.includes("https://") ? <a href={`${homeUrl}`}>
                                            <button type="button" className="btn btn-secondary" >{getLanguageContent("home")}</button>
                                        </a> :
                                            <Link to={`${homeUrl}`}>
                                                <button type="button" className="btn btn-secondary" >{getLanguageContent("home")}</button>
                                        </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-md-6 col-lg-8 mb-2">*/}
                        {/*    */}{/*<div className="p-4 mb-3">*/}
                        {/*    */}{/*    <p className="fs-4 text-center">{getLanguageContent("bookedscucsfly")}</p>*/}
                        {/*    */}{/*</div>*/}
                        {/*    <div className="text-center">*/}
                        {/*        <Link to="/dashboard">*/}
                        {/*            <button type="button" className="btn btn-primary me-2">{getLanguageContent("dshbrd")}</button>*/}
                        {/*        </Link>*/}
                        {/*        <Link to="/">*/}
                        {/*            <button type="button" className="btn btn-secondary">{getLanguageContent("home")}</button>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
        </>
    );
}



export const getEncounterMode = (value) => {
    switch (parseInt(value)) {
        case 1:
            return 'In Person';
        case 2:
            return 'Online';
        case 3:
            return 'Phone';
        default:
            return ''
    }
}

export const getConsultationMode = (value) => {
    switch (parseInt(value)) {
        case 1:
            return '15';
        case 2:
            return '30';
        case 3:
            return '45';
        case 4:
            return '60';
        default:
            return '';
    }
}

