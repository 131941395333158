
export const apiConst = {

    //auth
    authorizelogin: 'auth/ssologin',
    authorizeGet: 'auth/getssoauthorization',
    ssoSLOUrl: 'auth/getSingleLogoutUrl',
    ssologin: 'auth/connecttoken',
    postlogin: 'auth/login',
    postforgotpassword: 'auth/forgotpassword',
    postresetpassword: 'auth/resetpassword',
    postregister: 'auth/register',
    getauthprofile: 'auth/getauthprofile',
    authlogout: 'auth/logout',
    authcookies: 'auth/getauthcookies',
    getResource: "auth/getuserresource",
    validateAccount: "auth/validateactivation/",
    activateAccount: "auth/activateaccount",
    exitAuth: "auth/exitauth",

    //basicAccount

    gethost: 'basicaccount/gethostinfo',
    getPortalContent: 'basicaccount/getportalcontent',

    //calender 

    getmodes: 'calender/getcateogry/',

    //contact

    createcontact: 'contact/create',
    locationautocomplete: 'contact/autocomplete',
    updatecontact: 'contact/update/',

    //Content
    getContent: 'content/get/',

    //Condition
    symptomsList: 'dccondition/getsymptomlist',
    symptomConditions: 'dccondition/symptoms/',

    //customforms
    getformlist: 'customform/getforminput/',
    formsave: 'customform/create',
    formUpdate: 'customform/update/',
    formsList: 'customform/getforms/',
    getMultipleForms: 'customform/getmultipleforms/',
    //Doc
    docViewer: "doc/getviewerurl/",
    docupload: 'doc/upload',
    doclist: 'doc/doclist/',
    docupdate: 'doc/update',
    docdelete: 'doc/delete',
    getcateogry: "category/get/",
    docdownload: "doc/getdoc",
    docstatus: "doc/docstatus/",

    //Note

    getNotes: 'dcnote/get/',

    //encounter

    getencounter: 'encounter/getencounter/',
    encounterList: 'encounter/encounterlist/',
    getencounternote: 'encounter/getencounternote/',
    getencounterrule: 'encounter/getrule/',
    encountercreate: 'encounter/create',
    encounterpharmacy: 'encounter/updatepharmacy/',
    encountertemplate: 'encounter/gettemplate',
    //insurance

    getinsurance: 'dcinsurance/list',
    addinsurance: 'dcinsurance/create',
    updateinsurance: 'dcinsurance/update/',
    autocompleteinsurance: 'dcinsurance/autocomplete/',
    editinsurance: 'dcinsurance/get/',
    //LangResources

    getlangresource: 'langresource/getlangresource',



    //Message

    getMessage: "dcmessage/get",
    getMessageList: "dcmessage/get/",
    saveMessage: "dcmessage/create",

    //Provider

    getproviderlist: 'dcprovider/search',
    getproviderprofile: 'dcprovider/getprofile',
    gettimeslot: 'dcprovider/gettimeslot',
    getlicenseregion: 'dcprovider/getlicenseregion',
    getproviderspeciality: 'dcprovider/getspeciality',
    getAvailableProvider: 'dcprovider/getavailableprovider',
    getNotAssignedMember: 'dcprovider/notasssignedmember',
    providerRequest: 'dcprovider/providerappointmentrequest/',

    //patient

    patientinfo: 'patient/getinfo',
    updateResiding: 'patient/updtaeResiding',
    familymembersave: 'patient/familymembersave',
    familyMemberUpdate: 'patient/familymemberupdate/',
    familymemberget: 'patient/familymemberget/',
    getNotificationLog: 'patient/getnotificationlog/',
    patientInvite: 'patient/patientinvite/',


    getvital: "patienthealthmetric/vitallist/",
    getvitalform: "patienthealthmetric/gethealthmetrics/",
    vitalsave: "patienthealthmetric/healthmetricsave/",
    vitalUpdate: "patienthealthmetric/healthmetricupdate/",

    //Pharamacy

    pharamacyautocomplete: "dcpharmacy/cityautocomplete",
    pharamacysearch: "dcpharmacy/pharmacysearch/",
    pharamacypreferences: "dcpharmacy/pharmacypreferences/",
    preferencelist: "dcpharmacy/preferencelist/",
    getPharmacy: "dcpharmacy/pharmacy/",

    //Payment

    proceespayment: "dcpayment/getpayment/",
    paymentTransaction: "dcpayment/gettransaction",
    paymentlist: "dcpayment/getlist",
    getAdvancePricing: "dcpayment/getadvancepricing",

    // print

    prescriptionTemplateGet: 'rx/prescriptiontemplateget/',
    labTemplateGet: 'rx/labtemplateget/',
    radiologyTemplateGet: 'rx/radiologytemplateget/',

    //Plan
    getPlan: 'dcplan/getplan/',
    getPlanCode: 'dcplan/getplancode/',
    //labTemplateGet: 'rx/labtemplateget/',

    // rxDetailPage

    consultationdetail: 'rx/detaillist/',
    labdetail: 'rx/labdetaillist/',
    raddetail: 'rx/raddetaillist/',
    prescriptionList: 'rx/detaillist/',
    aproveConsent: 'rx/updateconsent',



    //Setting

    getresponsecode: 'setting/getresponsecode',
    getaccountsettings: 'setting/getaccountsetting',
    getsettingoptions: 'setting/getsettingoptions',
    getsettingvalues: 'setting/getsettingvalues/',
    autocompletereason: 'setting/autocompletereason',
    getreason: 'setting/getreason',
    getSpecialityreason: 'setting/specialityreason/',
    getaccountregionalentity: 'setting/getaccountresidingstate/',
    getAccountModules: 'setting/getaccountmodules/',
    getAccountCountry: 'setting/getaccountcountry',

    //schedule 

    getschedulelist: 'schedule/getappointmentwidget',
    scheduleconfirmation: "schedule/scheduleconfirm/",
    scheduleupdate: "schedule/updateconfirm",
    discard: 'schedule/discard/',
    pendingApointment: 'schedule/getpendingappointment',



    //speciality

    getAccountspeciality: "dcspeciality/list",
    getSpecialityServiceOption: "dcspeciality/specialityservice/",

    //Schedule

    schedulecreate: 'schedule/create',
    schedulesummary: 'schedule/getsummary/',

    //user

    getuserprofile: 'user/getprofile',
    updateprofile: 'user/updateprofile',
    updateprofilephoto: 'user/updateprofilephoto',
    deleteprofilephoto: 'user/deleteprofilephoto',
    changepassword: 'user/changepassword',
    userupdate: 'user/update',
    userpreference: 'user/updatepreference',

    //Country
    getCountryList: 'country/getlist'

};



export const appConst = {
    clientCodes: {
        gayaWellness: "GWL"
    },
    encStatusId: {
        initiated: 7,
        requested: 6,
        encounterInitated: 5,
        scheduled: 1,
        cancelled: 4
    },
    resourceCodes: {
        sharedCalender: "RW8",
        consultNowWithoutProvider: "GT6",
        consultLaterWithProvider: "SD3",
    },
    resource: {
        menu: 1,
        moduleMenu: 3,
        publicResource: 4
    },
    recordType: {
        schedule: 6,
        encounter: 7,
        proofUpload: 4,
        patientLevel: 4,
    },
    typeid: {
        accountstatetypeid: -1,
    },
    areaId: {
        intakeForms: 3,
        encounterLevel: 2,
        patientLevelForms: 4,
        directForms: 6,
    },
    cateogries: {
        documents: "doc_type",
        proofUpload: "identification_type",
        relationship: "relationship_type",
        medicaldoc_type: "medicaldoc_type",
        condition: "clinicaldoc_type",
        //relationship: "relationship_type",
    },
    fileTypes: {
        reason: {
            displayText: ["PNG", "JPEG", "JPG", "GIF", "PDF"],
            validationTypes: ["image/png", "image/jpeg", "image/jpg", "image/gif", "application/pdf"],
        },
        reasonCapture: {
            displayText: ["PNG", "JPEG", "JPG"],
            validationTypes: ["image/png", "image/jpeg", "image/jpg"],
        },
        profile: {
            displayText: ["PNG", "JPEG", "JPG", "GIF", "PDF"],
            validationTypes: ["image/png", "image/jpeg", "image/jpg", "image/gif", "application/pdf"],
        },
        encounter:
        {
            displayText: ["PDF", "DOC", "DOCX", "XLS", "XLSX",
                "JPEG",
                "JPG",
                "PNG",
                "GIF"
            ],
            validationTypes: ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "image/jpeg",
                "image/png",
                "image/gif"
            ],
        },
    },
    preferedPharmacy: {
        system: "TEM",
        patient: "TIE",
        pharmacyElectronic: "ELC",
        pharmacyEmail: "PHY",
        pharmacyPatient: "PPC",
    },
    encounterModes: {
        online: "TEL",
        inPerson: "INP",
        phone: "PHN",
        phoneOnline: "PHT"
    },
    calendarWeeks: {
        OWT: 1,
        TWT: 2,
        HWT: 3,
        FWT: 4
    }
}


export const resourceConst = {
    actions: {
        view: "VW",
        create: "CR",
        edit: "ED",
        print: "PNT",
        delete: "DEL",
        download: "DWNLD"
    }

}