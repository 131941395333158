import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverGet } from '../../../middleware/http';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { apiConst, resourceConst } from '../../../services/constant/constant';
import { dateFormat, getAuthProfile, getSearchParams, isEnabled } from '../../../services/utils/validation';
import ProgressContext from '../../../services/utils/progress';
import { useProfile } from '../../../services/utils/profileContext';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import LazyImageComponent from '../../../services/utils/LazyImageComponent';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useManageResource } from '../../../services/Context/ManageResourceContext';

export default function ProviderDetail() {

    const [profile, setProfile] = useState(null);
    const [availability, setAvailability] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { checkUserProfile, getFamilyList } = useProfile();
    const [consultLater, setConsultlater] = useState(null)
    const { progParams } = useContext(ProgressContext);
    const queryParams = new URLSearchParams(location.search);
    const memberId = queryParams.get('memberid');
    const { getLanguageContent } = useLanguageRes();
    const { accountSettings } = useAccountSettings();
    const { getCurrentResourceAction, actionExists } = useManageResource();

    const providerprofile = () => {
        progParams(true);

        const _typeId = getSearchParams("ftypeid");
        const specialityId = getSearchParams("spid");

        const pricingAtr = [
            {
                attributeid: "101",
                recordtypeid: "33",
                recordid: _typeId ? _typeId : "1"
            },
            {
                attributeid: "102",
                recordtypeid: "32",
                recordid: specialityId
            }
        ]

        serverGet(`${apiConst.getproviderprofile}/${memberId}?atr=${JSON.stringify(pricingAtr)}`).then((res) => {
            progParams(false)
            try {
                if (res?.data && res?.status == 1) {
                    setProfile(res?.data);

                    if (Array.isArray(res?.data?.availability) && res?.data.availability?.length > 0) {
                        const uniqueDates = [...new Set(res?.data.availability?.map(item => item.openTime.split('T')[0]))];
                        const grouped = uniqueDates.map(date => ({
                            date,
                            items: res?.data.availability.filter(item => item.openTime.split('T')[0] === date)
                        }));
                        setAvailability(grouped);
                    }

                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const booknowBtn = async () => {
        try {
            const residingId = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';

            const locationSearch = window.location.search

            const addProfileUrl = `/addprofile${locationSearch}`;
            const bookingUrl = `/booking${locationSearch}`;
            const _familyMemberUrl = `/selectfamilymember${locationSearch}`;
            const _identificationUrl = `/idverification/upload${locationSearch}`;

            const redirectUrl = {
                path: bookingUrl,
                bookingUrl: bookingUrl,
                familyMemberUrl: _familyMemberUrl,
                profileUrl: addProfileUrl,
            }

            if (!getAuthProfile.getProfileAuth()) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));

                return navigate(`/security/login?c=y${residingId ? `&${residingId}` : ""}`)
            }

            let _isProfile;
            let _isFamilyMember;

            if (getAuthProfile.getProfileAuth()) {
                _isProfile = await checkUserProfile();
                _isFamilyMember = await getFamilyList();
            }



            if (!_isProfile?.pofileCompleted) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(addProfileUrl);
            } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(_identificationUrl);
            } else if (_isFamilyMember) {
                return navigate(_familyMemberUrl);
            } else {
                return navigate(bookingUrl);
            }
        } catch (e) {
            console.error(e);
        }
    }



    useEffect(() => {
        providerprofile();
        document.title = "Provider profile";

        const _actionsConsultLater = getCurrentResourceAction("SD3", "DSB");

        setConsultlater(_actionsConsultLater)

    }, [])

    return (
        <div className="px-2 px-md-0">
            {profile && (
                <>
                    <div className="d-flex justify-content-between">
                        <ScheduleHeader title={getLanguageContent("prvdrprfle")} />
                        {(profile?.provider?.status) && (
                            <div className="my-auto">
                                <button onClick={booknowBtn} type="button" className="btn btn-primary">{getLanguageContent("boknw")}</button>
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-md-row flex-column">
                        <div className="card border-0 flex-shrink-0 wf-left">
                            <div className="card-body">
                                <div className="text-center">

                                    <LazyImageComponent
                                        alt={profile?.member?.firstName}
                                        className="rounded rounded-circle border border-1 p-2 mb-3"
                                        width="200"
                                        src={!profile?.member?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(profile?.member?.photoPath)}
                                    />


                                    <h5>{profile?.member?.firstName} {profile?.member?.lastName}</h5>
                                    {profile?.specialities?.length > 0 &&
                                        <small>{profile?.specialities?.filter((list) => list?.id != null)?.map((speciality, i, arr) => {
                                            return <span key={`speciality${i}`}>{speciality?.name}{i != (arr.length - 1) ? ', ' : ''} </span>
                                        })}
                                        </small>
                                    }
                                    {profile?.modes?.length > 0 && (
                                        <div className="p-3">
                                            {profile?.modes?.map((item, i) => (
                                                <span key={i} className="badge rounded-pill border text-primary border-primary me-2 mb-2">{item?.name}</span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <h5 class="card-title">{getLanguageContent("wrknghr")}</h5>
                                    {profile?.timeZone?.length > 0 && (
                                        <h6 class="card-subtitle mb-4 text-secondary">{profile?.timeZone[0]?.code}</h6>
                                    )}
                                    {availability?.length > 0 && availability?.map((list, i, arr) => (
                                        <>
                                            <div className="row" key={i}>
                                                <div className="col" key={`child${i}`}>
                                                    <small className="card-text">{dateFormat(arr[i]?.items[0]?.openTime, "dddd")}</small>
                                                </div>
                                                <div className="col" key={`childs${i}`}>
                                                    {list?.items?.map((item, index, array) => (
                                                        <>
                                                            <small className={`card-text`}>{dateFormat(item?.openTime, "hh:mm A")} - {dateFormat(item?.closeTime, "hh:mm A")}{index != (array.length - 1) ? ', ' : ''}</small><br />
                                                            {/*    {index % 2 != 0 ? <br /> : ""} */}
                                                        </>
                                                    ))}
                                                    {/*    <small key={`childs${i}`} className="card-text">{dateFormat(list?.openTime, "h A")} - {dateFormat(list?.closeTime, "h A")}</small>*/}
                                                </div>
                                            </div>
                                            <hr className="border-secondary-subtle" />
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 ms-md-3 flex-grow-1">
                            <div className="card-body">
                                {profile?.languages?.length > 0 && (
                                    <>
                                        <h5>{getLanguageContent("lnguge")}</h5>
                                        <p>
                                            {profile?.languages?.filter((list) => list?.id != null)?.map((language, i, arr) => {
                                                return <span key={`language${i}`} className="me-1">{language?.name} {i != (arr.length - 1) ? ', ' : ''} </span>
                                            })}
                                        </p>
                                    </>
                                )}
                                {profile?.provider?.bioData && (
                                    <>
                                        <h5 className="mt-4">{getLanguageContent("biodta")}</h5>
                                        <pre className="textarea-wrap">{profile?.provider?.bioData}</pre>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}


