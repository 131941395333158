import React, { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import he from "he";
import { serverGet } from '../../middleware/http';
import { apiConst } from '../../services/constant/constant';
import { useAccountSettings } from '../../services/Context/AccSettingContext';
import ProgressContext from '../../services/utils/progress';

const Home = () => {
    const [content, setContent] = useState(null);
    const [homePage, setHomePage] = useState(null);
    const { accountSettings, account } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);

    const getContent = () => {
        progParams(true);
        serverGet(`${apiConst.getContent}en-us?code=${"C01"}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setContent(res?.data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    useEffect(() => {
        document.title = "Homepage";

        const fetchData = async () => {
            try {
                await getContent();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {
            const data = account?.portalSettings?.find((list) => list?.code == "HPC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                setHomePage(decodedHTML)
            }
        }

    }, [account])


    return (
        <>
            <div className="d-flex justify-content-center align-items-center home-container">
                <div className="row justify-content-center">
                    <div className="col-sm-9 col-md-8 col-lg-10 text-center w-100 my-4">
                        {(content?.content && accountSettings?.length > 0) && (
                            <div
                                dangerouslySetInnerHTML={{ __html: homePage }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;