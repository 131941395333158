import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import NotifyContext from "../../../services/utils/notify";
import { ecnSession } from "../../../services/utils/validation";
import { useLanguageRes } from '../../../services/Context/LanguageContext';

export default function ProcessPayment() {

    const navigate = useNavigate();
    const { notifyToast } = useContext(NotifyContext);
    const { getLanguageContent } = useLanguageRes();


    const getStatus = async () => {


        const sessionStore = ecnSession();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const order_id = urlSearchParams.get('id');
        const schedule_id = urlSearchParams.get('eid');
        //const type_id = urlSearchParams.get('typeId');
        const click = urlSearchParams.get('cancelClick');
        try {
            if (schedule_id && schedule_id != "")
                return setTimeout(() => navigate(`/confirmation?eid=${schedule_id}`, { replace: true }), 1800);
            if (order_id && sessionStore?.encid) {
                setTimeout(async () => {
                    let temp = ""
                    const orderDetail = localStorage.getItem("orderDetail");
                    if (orderDetail) {

                        temp = JSON.parse(localStorage.getItem("orderDetail"));
                        temp = temp?.order?.ExtOrderId
                    }

                    const res = await serverGet(`${apiConst.scheduleupdate}?scheduleId=${sessionStore?.encid}&transactionId=${order_id}&paymethodType=${1}${temp ? `&orderid=${temp}` : ""}`);
                    if (res?.data?.paymentStatus && res?.status == 1) {
                        sessionStorage.removeItem('redirectUrl');
                        return navigate(`/confirmation?eid=${res?.data?.scheduleId}`, { replace: true });
                    } else {
                        navigate(`/payment/fail?click=${click}`, { replace: true });
                        var error = JSON.parse(res?.data?.errorMessage);
                        if (error)
                            notifyToast(error?.messageCode[0]);
                    }
                }, 2000);
            } else {
                navigate(`/payment/fail?click=${click}`, { replace: true });
            }
        } catch (e) {
            console.log(e);
        }
    }

    //useEffect(() => {
    //    const preventBack = () => {
    //        window.history.pushState(null, null, window.location.href);
    //        window.onpopstate = () => {
    //            window.history.go(1);
    //        };
    //    };

    //    preventBack();

    //    return () => {
    //        window.onpopstate = null;
    //    };

    //}, []);

    useEffect(() => {
        document.title = "Processing";
        getStatus();
    }, []);

    //useEffect(() => {
    //    const preventBack = () => {
    //        window.history.forward();
    //    };
    //    preventBack();
    //    return () => {
    //        window.onunload = null;
    //    };
    //}, []);



    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "78.5vh" }}>
                <div className="text-center my-auto">
                    <div className="spinner mx-auto"></div>
                    <h3>{getLanguageContent("pldrep")}</h3>
                </div>
            </div>
        </>
    )
}