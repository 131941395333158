import React, { useState, useRef, useContext } from 'react';
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import he from "he";
import VoiceRecorder from './AudioRecorder';
import AddMessage from './AddMessage';
import ProgressContext from '../../../services/utils/progress';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import { dateAndTime, dateTimeFormat, replaceQuilliClassToBootstrap } from '../../../services/utils/validation';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import ActionStatus from '../../../services/utils/actionStatus';
//import MessageList from './messageList';
//import AudioRecorder from './AudioRecorder';



export default function Message({ msgId, handleBack, list, getInboxList, edit }) {


    const [messages, setMessages] = useState([]);
    const [messageSubject, setMessageSubject] = useState("");
    const { progParams } = useContext(ProgressContext);

    const { getLanguageContent } = useLanguageRes();

    //const backButton = () => {
    //    handleBack();
    //}


    const getList = () => {
        setMessages([]);
        setMessageSubject("");
        progParams(true);
        serverGet(`${apiConst.getMessage}/${msgId}`).then((res) => {
            progParams(false)
            try {
                if (res?.data && res?.status == 1) {
                    setMessages(res?.data);
                    setMessageSubject(res?.data[0]?.subject);
                    getInboxList();

                }
            } catch (e) {
                console.log(e);
            }
        })
    }




    useEffect(() => {
        if (msgId) {
            // console.log(msgId);




            const fetchData = async () => {
                await getList();
                // await getInboxList();
            }
            fetchData();

        }

    }, [msgId]);

    return (
        <>

            <div className="card border-0 h-100">
                <div className="card-body">
                    {msgId ?
                        <>
                            <AddMessage msgId={msgId} list={messages} level="edit" messageSubject={messageSubject} getList={getList} fieldDisabled={true} backButton={handleBack} edit={edit} />

                            <div>
                                <MessageContainer list={messages} />
                            </div>

                        </> :
                        <>
                            <div className="d-flex align-items-center justify-content-center h-100">
                                {list != "loading" && (
                                    <ActionStatus description={`${getLanguageContent(list?.length < 0 || !list ? "msgemp" : "msgslt")}`} button={""} imageid="#svg_failed" onsyncchange={""} type="search" descriptionClass={"fw-normal"} />
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}



const MessageContainer = ({ list }) => {

    const { accountDate } = useAccountSettings();


    const getHtmlContent = (data) => {
        const cleanHtml = DOMPurify.sanitize(data);
        const decodedHTML = he.decode(cleanHtml);

        return decodedHTML;
    }
    return (
        <>
            {(Array.isArray(list) && list?.length > 0) && (
                list?.map((item) => (
                    <div className="border p-2 mb-3 rounded">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className="rounded-circle border" width="40" height="40" src={!item?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(item?.photoPath)} alt={"user_img"} alt="User Profile" />
                                        <h6 className="ms-2 d-inline">{item?.sender}</h6>
                                    </div>
                                    <div className="ms-auto">
                                        <h6 className="d-inline d-md-none ms-auto">{dateAndTime(item?.sentOn, accountDate?.value)}</h6>
                                        <h6 className="d-none d-md-inline ">{dateTimeFormat(item?.sentOn, `${accountDate?.value} hh:mm A`)}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-secondary">
                                {/*<div className="textfield-wrap" dangerouslySetInnerHTML={{ __html: getHtmlContent(item?.content) }}>*/}
                                <div className="textfield-wrap" dangerouslySetInnerHTML={{ __html: replaceQuilliClassToBootstrap(item.content) }}>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    )
}
