import React, { createContext, useState, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { Modal } from 'bootstrap';
import { canvasPreview } from '../utils/canvasPreview'
import { useDebounceEffect } from '../utils/useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { useProfile } from './profileContext';
import { useLanguageRes } from '../../services/Context/LanguageContext';

const ImageCropContext = createContext(null)

export default ImageCropContext;

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


export function ImageCropContextProvider({ children }) {
    const modalRef = useRef();
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(null);
    const [photoURL, setPhotoURL] = useState()
    const { submitProfile } = useProfile();
    const { getLanguageContent } = useLanguageRes();


    const showModel = (photoURL) => {
        setPhotoURL(photoURL);
        setImgSrc(photoURL.photoURL);
        setAspect(photoURL.aspect);
        var myModal = modalRef.current
        var bsModal = Modal.getInstance(myModal)
        if (!bsModal) {
            bsModal = new Modal(myModal, { keyboard: false })
            bsModal.show();
        }
        else {
            bsModal.show();
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e?.currentTarget
            setCrop(centerAspectCrop(width, height, aspect));
            setTimeout(() => {
                const canvas = document.getElementsByClassName('ReactCrop__crop-selection');
                setCompletedCrop({
                    height: canvas[0].offsetHeight,
                    unit: "%",
                    width: canvas[0].offsetWidth,
                    x: 5,
                    y: 5
                });
            }, 1000);

        }
    }

    //const onImageLoad = (e) => {
    //    setHeight(e?.currentTarget?.height);
    //    setWidth(e?.currentTarget?.width);
    //    setCompletedCrop({
    //        x: 0,
    //        y: 0,
    //        height: e?.currentTarget?.height,
    //        width: e?.currentTarget?.width,
    //        unit: '%'
    //    });
    //};
    async function CropClick() {
        try {
            if (!previewCanvasRef.current) {
                throw new Error('Crop canvas does not exist')
            }

            previewCanvasRef.current.toBlob((blob) => {
                if (!blob) {
                    throw new Error('Failed to create blob')
                }
                if (blobUrlRef.current) {
                    URL.revokeObjectURL(blobUrlRef.current)
                }               
                blobUrlRef.current = URL.createObjectURL(blob)            
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async function () {
                    photoURL.setCropedURL(reader.result);
                    resizeAndConvertToBase64(blob, 3, function (resizedBase64String) {
                        submitProfile(`data:${blob.type};base64,` + resizedBase64String)
                    });
                }
                photoURL.setOpenCrop(false);
            })
        } catch (e) {
            console.error(e)

        }
    }

    function resizeAndConvertToBase64(blob, targetSizeInMB, callback) {
        const targetSizeInBytes = targetSizeInMB * 1024 * 1024;

        const img = new Image();

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;

            // Calculate the new dimensions based on the target size
            const newWidth = Math.sqrt(targetSizeInBytes * aspectRatio);
            const newHeight = newWidth / aspectRatio;

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convert the canvas content back to a Blob
            canvas.toBlob(function (resultBlob) {
                // Convert the Blob to a base64 string
                const reader = new FileReader();
                reader.onloadend = function () {
                    const base64String = reader.result.split(',')[1];
                    callback(base64String);
                };
                reader.readAsDataURL(resultBlob);
            });
        };

        // Convert the Blob to a data URL and set it as the source of the image
        const reader = new FileReader();
        reader.onload = function () {
            img.src = reader.result;
        };
        reader.readAsDataURL(blob);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    return (
        <ImageCropContext.Provider value={{ showCropImage: showModel }}>
            {children}
            <div className="modal fade" data-bs-backdrop="static" ref={modalRef} >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">{getLanguageContent("dasimu")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setCrop(null)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {!!imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    ruleOfThirds={true}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                            {!!completedCrop && (
                                <>
                                    <div style={{ display: "none" }} >
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                                border: '1px solid black',
                                                objectFit: 'contain',

                                                width: completedCrop.width,
                                                height: completedCrop.height,
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                setCrop(null)
                                photoURL?.setOpenCrop(true)
                            }}
                                data-bs-dismiss="modal">{getLanguageContent("dascan")}</button>
                            <button type="button" className="btn btn-primary" onClick={CropClick} data-bs-dismiss="modal">{getLanguageContent("dascrp")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </ImageCropContext.Provider>
    )
}

